<style lang="scss">
th.th-modal__recuento{
  background-color: white !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
</style>

<template>
<!--eslint-disable-->
  <div>
    <v-dialog
    v-model="dialog"
    persistent
    max-width="650px"
    >
      <v-card>
        <v-card-title>
          <p class="bold mb-0 txt-enasyo">{{ $t('ensayos.title') }}</p>
        </v-card-title> 
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pt-0">
              <p class="mb-0 fs16 text-left">{{ $t('ensayos.title2') }}</p>
            </v-col> 
            <v-col cols="12" class="py-0">
              <v-select 
                item-value="id"
                item-text="valor"
                v-model="tipoResultado"
                :items="tiposDeResultado"
                label="Results notation"
                append-icon="mdi-chevron-down"
                outlined
                dense
                required
                ></v-select> 
            </v-col> 
            <!-- <v-col cols="12">
              <v-checkbox
                label="Set this choice as default for all count-type results"
                class="shrink mt-0 pt-0 fs16"
                ></v-checkbox> 
            </v-col> -->
            <hr class="c-hr mx-4">
            <v-col cols="12">
              <p class="mb-0 fs16 text-left bold">Count-type results classification</p>
            </v-col> 
            <v-col cols="12 py-0">
              <p class="mb-0 fs14 text-left">Please set up and manage count-type results classification. 
                Each classification will add specific text that may be relevant to your numeric result, e.g., 1x10^3 CFU/mL <b>(RPES)</b>. 
                Classifications will appear when publishing count-type results.</p>
            </v-col> 
            <v-col class="py-0" cols="12 c-table-analisys">
              <v-data-table
                :headers="headers1"
                :items="catalogoConfigResult"
                :hide-default-footer="true"
                class="elevation-1"
              >
              <template v-slot:header.accion="{header}">
                <v-btn
                  class="fs14 bold"
                  text
                  color="#007AFF"
                  @click="agregarInformarEn()"
                >
                <v-icon>mdi-plus-circle-outline</v-icon>
                  Add new
                </v-btn>
              </template>
              
              <template v-slot:item.name="{ item }">
                <v-text-field
                  dense
                  outlined
                  :placeholder="item.nombre"
                  :disabled="item.ind_default"
                ></v-text-field>
              </template>
              <template v-slot:item.Adds="{ item }">
                <v-text-field
                  dense
                  outlined
                  :placeholder="item.operacion"
                  :disabled="item.ind_default"
                ></v-text-field>
              </template>
              <template v-slot:item.accion="{ item }">
                <div v-if="item.default == true" class="d-flex align-items-center">
                  <v-switch
                    class="my-0 py-0"
                    v-model="item.activo"
                  ></v-switch>
                  <!-- <label class="ml-2">llll</label> -->
                </div>
                <div v-else class="d-flex">
                  <div class="d-flex">
                    <v-switch
                      v-model="item.activo"
                    ></v-switch>
                    <!-- <label class="ml-2">{{switch1.toString()}}</label>  -->
                  </div>
                  <v-btn icon>
                    <svg width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                      <g id="edit" transform="translate(-1.25 -1.129)">
                        <path id="Trazado_40996" data-name="Trazado 40996" d="M9.269,4H3.615A1.615,1.615,0,0,0,2,5.615V16.922a1.615,1.615,0,0,0,1.615,1.615H14.922a1.615,1.615,0,0,0,1.615-1.615V11.269" transform="translate(0 -0.658)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Trazado_40997" data-name="Trazado 40997" d="M15.241,2.307A1.463,1.463,0,0,1,17.31,4.376l-6.551,6.551L8,11.617l.69-2.758Z" transform="translate(0.262)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      </g>
                    </svg>
                  </v-btn>
                  <v-btn icon>
                    <svg width="17.5" height="17.5" viewBox="0 0 15.4 17">
                      <g id="trash-2" transform="translate(-2.5 -1.5)">
                        <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                        <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                        <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                        <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                      </g>
                    </svg>
                  </v-btn>
                  
                  
                </div>
              </template>

            </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>          
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModalRecuento()"
            class="c-btn"
            color="#007AFF"
            outlined
          >
            {{ $t('ensayos.cancelar') }}
          </v-btn>
          <v-btn
              class="ml-5 c-btn c-btn--blue bold"
              outlined
              @click="guardarConfiguracionResultados()"
          >
              {{ $t('ensayos.guardar') }}
          </v-btn>
        </v-card-actions>  
      </v-card>
    </v-dialog>      
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations } from 'vuex';
import Service from '../../services/apis';
import i18n from '../../plugins/il8n';

export default {
  data: () => ({
    switch1: false,
    dialog: false,
    tipoResultado: 0,
    search: '',
    parametronombre: '',
    headers1: [
      {
        text: 'Classification name',
        align: 'left',
        sortable: false,
        value: 'name',
        class: 'th-modal__recuento',
      },
      {
        text: 'Adds',
        align: 'left',
        value: 'Adds',
        sortable: false,
        class: 'th-modal__recuento',
      },
      {
        text: '',
        value: 'accion',
        sortable: false,
        class: 'th-modal__recuento',
      },
    ],
    catalogoConfigResult: [],
    tiposDeResultado: [
      {
        id: 0,
        valor: 'Standard notation (e.g. 1000)',
      },
      {
        id: 1,
        valor: 'Scientific notation (e.g. 1x10^3)',
      },
    ],
    headers: [
      {
        text: i18n.t('ensayos.title2'),
        class: 'thparametro',
        align: 'start',
        value: 'nombre',
        sortable: false,
      },
    ],
    snack: {
      snackbar: false,
      txt: 'null',
      color: 'red',
      error: false,
      x: null,
      y: 'top',
      colorbtn: '#fff',
    },
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo']),
  },
  methods: {
    ...mapMutations('Ensayos', ['setMix']),
    openmodal() {
      console.log('hola', this.data_ensayo);
      this.dialog = true;
      this.headers.text = i18n.t('ensayos.title2');

      const test = this.data_ensayo.catalogoTipopublicacion.filter((cat) => cat.ind_default === true
      || cat.tb_r_cliente_tipo_result_rto != null);
      this.catalogoConfigResult = [];
      test.forEach((element) => {
        // const activo = !!((element.tb_r_cliente_tipo_result_rto
        // && element.tb_r_cliente_tipo_result_rto.ind_activo));
        const activo = (element.tb_r_cliente_tipo_result_rto && element.tb_r_cliente_tipo_result_rto.ind_activo) ? true : false;
        this.catalogoConfigResult.push({
          id: element.id,
          nombre: element.sn_nombre,
          operacion: element.sn_operacion,
          default: element.ind_default,
          activo,
          relaciontabla: element.tb_r_cliente_tipo_result_rto,
        });
      });

      // this.catalogoConfigResult = test;
      console.log({ test: this.catalogoConfigResult });
    },
    closeModalRecuento() {
      this.dialog = false;
      this.addFields = 0;
      this.classificationResult = {};
    },
    agregarInformarEn() {
      // this.addFields = 0;
      const classificationResult = {};
      this.addFields += 1;
      // console.log(this.addFields);
      if (this.addFields > 0) {
        this.classificationResult = {
          id: this.id + this.addFields,
          nombre: '',
          operacion: '',
          default: false,
          activo: false,
        };
        this.items.push(classificationResult);
      }
    },
    async guardarConfiguracionResultados() {
      console.log(this.catalogoConfigResult);
      const dataEnviar = {
        tiporesultado: this.tipoResultado,
        tipoPublicacion: this.catalogoConfigResult
      };
      const configuracionResultado = await Service.apiToken('POST', 'guardar-Configuracionresultados', this.$ls.storage.token, dataEnviar);
      console.log({ configuracionResultado });
    },
  },
};
</script>
